import "./DisplayCompanies.css";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router";
import Button from "../../../components/common/button/Button";
import Table from "../../../components/common/table/Table";
import { openModal } from "../../../redux/modal/modalActions";
import { useDispatch } from "react-redux";
import { getAllCompanies } from "../../../apis/companiesApis/getAllCompanies";
import ResponseModal from "../../../components/common/modal/responseModal/ResponseModal";
import { routeNames } from "../../../consts/routesNames";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { getCookie } from "../../../utils/cookies";
import { createMenuItem } from "../../../helpers/menuHelper";
import ConfirmationBox from "../../../components/common/confirmationBox/ConfirmationBox";
import { deleteCompany } from "../../../apis/companiesApis/deleteCompany";

function DisplayCompanies({ homePageDisplay }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = useRef(getCookie("userRole"));

  const [companiesData, setCompaniesData] = useState([]);

  const getCompanies = async () => {
    try {
      const allCompaniesRes = await getAllCompanies();
      if (
        allCompaniesRes.error_code === 2 ||
        allCompaniesRes.error_code === 3
      ) {
        dispatch(
          openModal(
            <ResponseModal
              text={allCompaniesRes.error_message}
              isError={true}
            />
          )
        );
      } else {
        let companiesArray = [];
        if (allCompaniesRes.res.data.length > 0) {
          companiesArray = allCompaniesRes.res.data.map((company) => ({
            ...company,
            address: `${company.street_number ?? "-"} ${
              company.street_name ?? "-"
            }, ${company.city ?? "-"}, ${company.state ?? "-"} ${
              company.zip_code ?? "-"
            }`,
            category_name: company.category.name,
          }));
        }
        setCompaniesData(companiesArray);
      }
    } catch (error) {
      console.error("Error fetching companies :", error);
    }
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const tableHeaders = [
    {
      key: "id",
      name: "ID",
    },
    {
      key: "name",
      name: "Name",
    },
    {
      key: "address",
      name: "Full Address",
    },
    {
      key: "category_name",
      name: "Category",
    },
    {
      key: "website",
      name: "Website",
    },
  ];

  const errorDispatcher = (message) => {
    alert(message);
    dispatch(openModal(<ResponseModal text={message} isError={true} />));
  };

  const HandleCompaniesDetailsClick = (rowData) => {
    navigate(routeNames.COMPANY_DETAILS, { state: { data: rowData } });
  };

  const editCompany = (rowData) => {
    navigate(`${routeNames.EDIT_COMPANY.split(":")[0]}${rowData.id}`, {
      state: { data: rowData },
    });
  };

  const confirmDeleteCompany = (rowData) => {
    dispatch(
      openModal(
        <ConfirmationBox
          msg={`Are you sure you want to delete "${rowData.name}"`}
          saveText={"OK"}
          discardText={"Cancel"}
          saveAction={async () => {
            try {
              await deleteCompany(rowData.id);
              errorDispatcher("Deleted Successfully");
              getCompanies();
            } catch (error) {
              console.error("Error deleting company:", error);
              errorDispatcher("Failed to delete the company.");
            }
          }}
        />
      )
    );
  };

  const HandleAddNewCompanyClick = () => {
    navigate(routeNames.ADD_COMPANY);
  };

  return (
    <>
      <div
        className={
          homePageDisplay
            ? "main-container-displayCompanies-homePageDisplay"
            : "main-container-displayCompanies"
        }
      >
        {!homePageDisplay ? (
          <div className="btns-main-container-displayCompanies">
            <div className="add-btn-container-displayCompanies">
              <Button
                className={"add-btn-displayCompanies"}
                buttonText={"Add New Company"}
                onClick={HandleAddNewCompanyClick}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="table-main-container-displayCompanies">
          <Table
            searchable={homePageDisplay ? false : true}
            tableHeight={homePageDisplay ? "450px" : "650px"}
            headers={tableHeaders}
            rows={companiesData}
            onRowClick={HandleCompaniesDetailsClick}
            rowActions={
              userRole.current !== "others" && [
                createMenuItem("Edit", editCompany, <EditIcon />),
                createMenuItem("Delete", confirmDeleteCompany, <DeleteIcon />),
              ]
            }
          />
        </div>
      </div>
    </>
  );
}

export default DisplayCompanies;
