import React, { useState, useRef } from "react";
import "./CompanyDetails.css";
import myImage from "../../../assets/images/contact.svg.png";
import { useLocation } from "react-router";
import trash from "../../../assets/images/trash.svg";
import Button from "../../../components/common/button/Button";
import appConfig from "../../../config.json";
import axios from "axios";
import { BACKEND_URL } from "../../../constants";
import { getCookie } from "../../../utils/cookies";

function CompanyDetails() {
  const backEndUrl = `${BACKEND_URL}`;
  const userRole = useRef(getCookie("userRole"));

  const location = useLocation();
  const data = location?.state?.data;

  const [itemImage, setItemImage] = useState(
    location?.state?.data.profile_picture
  );

  const handleImageChange = (e) => {
    if (e.target.files[0]) changeImages(e.target.files[0]);
  };

  const deleteImage = () => {
    changeImages();
  };

  const changeImages = (image) => {
    const url =
      backEndUrl + appConfig.backend.routes.companies.updateCompanyImages;

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(
        url,
        {
          company_id: location?.state?.data?.id,
          profile_picture: image ? image : {},
        },
        config
      )
      .then(() => {
        setItemImage(image ? URL.createObjectURL(image) : null);
      });
  };

  return (
    <>
      <div className="main-container-companyDetails">
        <div className="section-main-container-companyDetails">
          {/* 1st Row Company info and Address */}
          <div className="section-row-container-companyDetails">
            <div className="section-container-companyDetails">
              <div className="section-container-companyDetails-companyInfo">
                {/* Profile Picture */}
                <div className="column-companyInfo-profile-picture">
                  <div>
                    <img
                      src={itemImage ? itemImage : myImage}
                      alt="Profile Picture"
                    />
                  </div>
                  {userRole.current !== "others" && (
                    <div className="image-controls">
                      <label class="image-btn">
                        <input
                          type="file"
                          id="propertyImage"
                          accept="image/*,.pdf"
                          onChange={(e) => handleImageChange(e)}
                        />
                        Edit Image
                      </label>
                      {itemImage !== null ? (
                        <Button
                          className={"delete-image-btn-items"}
                          buttonText={
                            <img
                              style={{ height: "75%" }}
                              src={trash}
                              alt="Delete Image"
                            />
                          }
                          onClick={() => deleteImage()}
                        />
                      ) : null}
                    </div>
                  )}
                </div>
                {/* Company Info */}
                <div className="column-companyInfo">
                  <h2> {data.name} Details</h2>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Street Number:</strong> {data.street_number}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Street Name:</strong> {data.street_name}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>City:</strong> {data.city}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>State:</strong> {data.state}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Country:</strong> {data.country}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Zip Code:</strong> {data.zip_code}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Category:</strong> {data.category_name}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Website:</strong>{" "}
                      {data.website !== null ? data.website : ""}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Comment:</strong> {data.comment}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 2nd Row Bank info and Address */}
        <div className="section-row-container-companyDetails">
          <div className="section-container-companyDetails">
            <div className="section-container-companyDetails-bankInfo">
              {/* Bank Info */}
              <div className="column-companyInfo">
                <h2>
                  {" "}
                  {data.first_name} {data.last_name} Bank Info
                </h2>
                <div className="label-text-container">
                  <p>
                    {" "}
                    <strong>Bank Name:</strong>{" "}
                    {data.company_bank && data.company_bank.name !== null
                      ? data.company_bank.name
                      : ""}
                  </p>
                </div>
                <div className="label-text-container">
                  <p>
                    {" "}
                    <strong>Account Name:</strong>{" "}
                    {data.company_bank_account_name}
                  </p>
                </div>
                <div className="label-text-container">
                  <p>
                    {" "}
                    <strong>Account Type:</strong>{" "}
                    {data.company_bank_account_type}
                  </p>
                </div>
                <div className="label-text-container">
                  <p>
                    {" "}
                    <strong>Account Number:</strong>{" "}
                    {data.company_bank_account_number}
                  </p>
                </div>
                <div className="label-text-container">
                  <p>
                    {" "}
                    <strong>Routing Number:</strong>{" "}
                    {data.company_bank_routing_number}
                  </p>
                </div>
                <div className="label-text-container">
                  <p>
                    {" "}
                    <strong>Street Number:</strong> {data.bank_street_number}
                  </p>
                </div>
                <div className="label-text-container">
                  <p>
                    {" "}
                    <strong>Street Name:</strong> {data.bank_street_name}
                  </p>
                </div>
                <div className="label-text-container">
                  <p>
                    {" "}
                    <strong>City:</strong> {data.bank_city}
                  </p>
                </div>
                <div className="label-text-container">
                  <p>
                    {" "}
                    <strong>State:</strong> {data.bank_state}
                  </p>
                </div>
                <div className="label-text-container">
                  <p>
                    {" "}
                    <strong>Country:</strong> {data.bank_country}
                  </p>
                </div>
                <div className="label-text-container">
                  <p>
                    {" "}
                    <strong>Zip Code:</strong> {data.bank_zip_code}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyDetails;
