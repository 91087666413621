export const bankAccountTypeOptions = [
  {
    name: "Checking",
    value: "checking",
  },
  {
    name: "Credit Card",
    value: "credit_card",
  },
  {
    name: "Saving",
    value: "saving",
  },
];
