import React, { useEffect } from "react";
import "./Navbar.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setActiveNavTab } from "../../redux/navbar/navbarActions";
import { navtabs } from "../../consts/navTabs";
import Button from "../common/button/Button";
import { routeNames } from "../../consts/routesNames";

function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { activeTab } = useSelector((state) => state.navbar);

  //Set active tab according to pathname on rendering of the navbar
  useEffect(() => {
    if (location.pathname === routeNames.HOME) {
      dispatch(setActiveNavTab(navtabs.HOME));
    }
  }, [dispatch, location.pathname]);

  return (
    <div
      className={
        activeTab === navtabs.LOGIN || activeTab === navtabs.REGISTER
          ? "nav-hidden"
          : "nav-container"
      }
    >
      <div className="nav-subcontainer">
        {/* <div className="nav-img-container">
          Makan El Logo
          <img src={zanobiaLogo} alt="" /> 
        </div> */}

        {/* NAV LIST CONTAINER */}
        <div>
          <ul className="nav-list-container">
            <li
              id="nav-list-orders"
              className={
                activeTab === navtabs.HOME
                  ? "nav-list-tab nav-tab-active"
                  : "nav-list-tab"
              }
              onClick={() => {
                dispatch(setActiveNavTab(navtabs.HOME));
                navigate(routeNames.HOME);
              }}
            >
              <div className="nav-list-tab-text">Home</div>
            </li>
            <li
              className={
                activeTab === navtabs.CONTACTS
                  ? "nav-list-tab nav-tab-active"
                  : "nav-list-tab"
              }
              onClick={() => {
                dispatch(setActiveNavTab(navtabs.CONTACTS));
                navigate(routeNames.CONTACTS);
              }}
            >
              <div className="nav-list-tab-text">Contacts</div>
            </li>
            <li
              className={
                activeTab === navtabs.ITEMS
                  ? "nav-list-tab nav-tab-active"
                  : "nav-list-tab"
              }
              onClick={() => {
                dispatch(setActiveNavTab(navtabs.ITEMS));
                navigate(routeNames.ITEMS);
              }}
            >
              <div className="nav-list-tab-text">Items</div>
            </li>
            <li
              className={
                activeTab === navtabs.PROPERTIES
                  ? "nav-list-tab nav-tab-active"
                  : "nav-list-tab"
              }
              onClick={() => {
                dispatch(setActiveNavTab(navtabs.PROPERTIES));
                navigate(routeNames.PROPERTIES);
              }}
            >
              <div className="nav-list-tab-text">Projects</div>
            </li>
            <li
        className={
          activeTab === navtabs.COMPANY
            ? "nav-list-tab mobile-nav-tab-active"
            : "nav-list-tab"
        }
        onClick={() => {
          dispatch(setActiveNavTab(navtabs.COMPANY));
          navigate(routeNames.COMPANIES);
        }}
      >
        <div className="nav-list-tab-text">Companies</div>
      </li>
            <li
              className={
                activeTab === navtabs.LISTS
                  ? "nav-list-tab nav-tab-active"
                  : "nav-list-tab"
              }
              onClick={() => {
                dispatch(setActiveNavTab(navtabs.LISTS));
                navigate(routeNames.LISTS);
              }}
            >
              <div className="nav-list-tab-text">Lists</div>
            </li>
          </ul>
        </div>
        {/* BUTTON CONTAINER */}
        <div className="nav-auth-container">
          <Button
            className={"nav-button"}
            buttonText={"Logout"}
            onClick={() => navigate("/logout")}
          />
        </div>
      </div>
    </div>
  );
}

export default Navbar;
