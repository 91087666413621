export const navtabs = {
  LOGIN: "LOGIN",
  REGISTER: "REGISTER",
  HOME: "HOME",
  CONTACTS: "CONTACTS",
  ITEMS: "ITEMS",
  PROPERTIES: "PROPERTIES",
  LISTS: "LISTS",
  COMPANY : "COMPANY",
};
