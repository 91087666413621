import urlConfig from "../../config.json";
import { BACKEND_URL } from "../../constants";
import axios from "axios";

export const deleteCompany = async (companyId) => {
  const ROUTE = urlConfig.backend.routes.companies.deleteCompany;
  const url = `${BACKEND_URL}${ROUTE}`;

  return await axios
    .delete(url, {
      data: { company_id: companyId },
    })
    .then((res) => {
      if (res.status === 200) {
        return { res: res.data, error_code: 1 };
      } else {
        return {
          error_code: 2,
          error_message: "Something Went Wrong please try again later",
        };
      }
    })
    .catch((error) => {
      if (error.response.status === 400) {
        return {
          error_code: 3,
          error_message: "Something Went Wrong please try again later",
        };
      } else {
        alert(error.response.data.message);
      }
    });
};
