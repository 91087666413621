import React, { useEffect, useState, useRef } from "react";
import "./PropertyDetails.css";
import myImage from "../../../assets/images/house.png";
import trash from "../../../assets/images/trash.svg";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../redux/modal/modalActions";
import { routeNames } from "../../../consts/routesNames";
import TextField from "../../../components/common/textfield/TextField";
import Button from "../../../components/common/button/Button";
import CheckBox from "../../../components/common/checkBox/CheckBox";
import Carousel from "../../../components/common/carousel/ItemsCarousel";
import ResponseModal from "../../../components/common/modal/responseModal/ResponseModal";
import { getProcessPropertyStatusById } from "../../../apis/processesApis/getProcessPropertyStatusById";
import { updateProcessPropertyStatuses } from "../../../apis/processesApis/updateProcessPropertyStatuses";
import {
  deletePropertyImages,
  updatePropertyImages,
  updatePropertyNotes,
} from "../../../redux/projects/projectsAction";
import {
  propertyImagesSelector,
  propertyNotesSelector,
} from "../../../redux/projects/projectSelector";
import { getCookie } from "../../../utils/cookies";

function PropertyDetails() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = useRef(getCookie("userRole"));

  const propertyImages = useSelector(propertyImagesSelector);
  const propertyNotes = useSelector(propertyNotesSelector);
  const data = location?.state?.data;
  const [processesData, setProcessesData] = useState([]);
  const [statusesData, setStatusesData] = useState([]);
  const [images, setImages] = useState([]);
  const [profileImage, setProfileImage] = useState([]);
  const [notes, setNotes] = useState("");

  // === Getting Processes Data  ===
  const getProcessPropertyStatusByPropertyId = async (propertyId) => {
    try {
      const allProcessesRes = await getProcessPropertyStatusById(propertyId);
      if (
        allProcessesRes.error_code === 2 ||
        allProcessesRes.error_code === 3
      ) {
        dispatch(
          openModal(
            <ResponseModal
              text={allProcessesRes.error_message}
              isError={true}
            />
          )
        );
      } else {
        const groupedData = groupByPhase(allProcessesRes.res.data);
        setProcessesData(groupedData);
        initializeStatusesData(groupedData);
      }
    } catch (error) {
      console.error("Error fetching Processes :", error);
    }
  };

  const initializeStatusesData = (processesData) => {
    const initialStatuses = [];
    Object.entries(processesData).forEach(([_, subPhases]) => {
      subPhases.forEach((subPhase) => {
        initialStatuses.push({
          property_id: data.id,
          process_property_status_id: subPhase.process_id,
          is_completed: subPhase.is_completed,
        });
      });
    });
    setStatusesData(initialStatuses);
  };

  const groupByPhase = (data) => {
    return data.reduce((acc, item) => {
      const phaseName = item.process.phase.name;
      if (!acc[phaseName]) {
        acc[phaseName] = [];
      }
      acc[phaseName].push({
        is_completed: item.is_completed,
        process_id: item.id, // Assuming this is how you access the ID
        name: item.process.sub_phase.name,
      });
      return acc;
    }, {});
  };
  useEffect(() => {
    setProfileImage(propertyImages.property_main_picture);
    setImages(propertyImages.property_images);
  }, [propertyImages]);

  // useEffect(() => {
  //   setNotes(propertyNotes);
  // }, [propertyNotes]);

  useEffect(() => {
    setNotes(data?.notes);
    setProfileImage(data?.property_main_picture);
    setImages(data?.property_images?.length > 0 ? data?.property_images : []);
    getProcessPropertyStatusByPropertyId(data.id);
  }, []);

  // === Handle Checkbox Change ===
  const handleCheckboxChange = (processId, isChecked) => {
    const updatedStatuses = [...statusesData];
    const index = updatedStatuses.findIndex(
      (item) => item.process_property_status_id === processId
    );

    if (index >= 0) {
      updatedStatuses[index].is_completed = isChecked;
    } else {
      console.error("Process ID not found in statusesData:", processId);
    }

    setStatusesData(updatedStatuses);
  };

  // === Edit Handlers ===
  const updateStatusesHandler = async (e) => {
    e.preventDefault();
    const updateStatusesRes = await updateProcessPropertyStatuses(statusesData);
    if (updateStatusesRes.error_code === 1) {
      alert("Statuses Updated Successfully");
      navigate(routeNames.PROPERTIES);
    }
  };
  const deleteImage = (img) => {
    dispatch(
      deletePropertyImages({
        propertyImageId: img.id,
        propertyId: location?.state?.data?.id,
      })
    );
  };

  const createImages = () => {
    const imageComponents = [];
    images?.map((img) => {
      imageComponents.push(
        <div>
          {userRole.current !== "others" && (
            <div>
              <Button
                className={"delete-image-btn"}
                buttonText={
                  <img
                    style={{ height: "75%" }}
                    src={trash}
                    alt="Delete Image"
                  />
                }
                onClick={() => deleteImage(img)}
              />
            </div>
          )}
          <img
            key={img?.id}
            className="img-class"
            src={img?.image}
            alt="carousel"
          />
        </div>
      );
    });
    return imageComponents;
  };

  const handleImageChange = (e, type) => {
    const payload = {
      imageFlag: null,
      images: null,
      propertyId: location?.state?.data?.id,
    };

    switch (type) {
      case "single":
        payload.imageFlag = "profile-image";
        payload.images = e.target.files[0];
        break;

      case "mutiple":
        const arrayOfObjects = Object.keys(e.target.files).map(
          (key) => e.target.files[key]
        );
        payload.imageFlag = "property-images";
        payload.images = arrayOfObjects;
        break;

      default:
        break;
    }

    dispatch(updatePropertyImages(payload));
  };

  return (
    <>
      <div className="main-container-propertyDetails">
        <div className="section-main-container-propertyDetails">
          {/* 1st Row Property primary info */}
          <div className="section-row-container-propertyDetails">
            <div className="section-container-propertyDetails">
              <div className="section-container-propertyDetails-propertyInfo">
                {/* property Info */}
                <div className="column-propertyInfo-single">
                  <h3>
                    {" "}
                    {data.street_number} {data.street_name} {data.city}{" "}
                    {data.state} {data.zip_code}
                  </h3>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Start Date:</strong> {data.start_date}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Close Date:</strong> {data.close_date}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Project Type:</strong> {data.type.name}
                    </p>
                  </div>
                  <div className="label-text-container">
                    <p>
                      {" "}
                      <strong>Project Status:</strong> {data.status}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 2nd Row Property profile picture + Notes */}
          <div className="section-row-container-propertyDetails">
            <div className="section-container-propertyDetails">
              <div className="section-container-propertyDetails-bankInfo">
                {/* Profile Picture */}
                <div className="label-text-container">
                  <h3>
                    {" "}
                    <strong> Project Profile Picture:</strong>
                  </h3>
                  <div className="column-propertyInfo-profile-picture">
                    <img
                      src={profileImage ? profileImage : myImage}
                      alt="Profile Picture"
                    />
                  </div>
                  {userRole.current !== "others" && (
                    <div className="save-button-container">
                      <label class="image-btn">
                        <input
                          type="file"
                          id="propertyImage"
                          accept="image/*,.pdf"
                          onChange={(e) => handleImageChange(e, "single")}
                        />
                        Edit Image
                      </label>
                    </div>
                  )}
                </div>
                {/* Property Notes */}
                <div className="column-propertyInfo">
                  <h3> Project Notes :</h3>
                  <div className="label-text-container">
                    <div className="labeltext-container-propertyNotes">
                      <TextField
                        className="project-notes-textfield"
                        name="notes"
                        type="multilined"
                        disabled={false}
                        setValue={setNotes}
                        value={notes}
                      />
                    </div>
                  </div>
                  {userRole.current !== "others" && (
                    <div className="edit-button-container">
                      <Button
                        buttonText={"Edit Notes"}
                        onClick={() => {
                          dispatch(
                            updatePropertyNotes({
                              notes: notes,
                              propertyId: location?.state?.data?.id,
                            })
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* 3nd Row Property Images */}

          <div className="section-row-container-propertyDetails">
            <div className="section-container-propertyDetails">
              <div className="section-container-propertyDetails-bankInfo">
                <div className="App" style={{ width: "99%" }}>
                  {images?.length > 0 ? (
                    <Carousel items={createImages()} numberOfDeskTopItems={1} />
                  ) : (
                    <div className="no-images-found">No Images Found</div>
                  )}
                  {userRole.current !== "others" && (
                    <div className="edit-image-container">
                      <label class="image-btn">
                        <input
                          type="file"
                          id="propertyImage"
                          accept="image/png, image/jpeg"
                          multiple
                          onChange={(e) => handleImageChange(e, "mutiple")}
                        />
                        {images?.length > 0 ? "Edit Images" : "Add Images"}
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* 3rd Row Property info and Address */}
          <div className="section-row-container-propertyDetails">
            <div className="section-container-propertyDetails">
              <div className="section-container-propertyDetails-bankInfo">
                {/* Property Address */}
                <div className="flex-wrap">
                  <div className="propertyInfo-section">
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Street Number:</strong>
                      </p>
                      {data.street_number}
                    </div>
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Street Name:</strong>
                      </p>
                      {data.street_name}
                    </div>
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>City:</strong>
                      </p>
                      {data.city}
                    </div>
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>State:</strong>
                      </p>
                      {data.state}
                    </div>
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Country:</strong>
                      </p>
                      {data.country}
                    </div>
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Zip Code:</strong>
                      </p>
                      {data.zip_code}
                    </div>
                  </div>
                  {/* Property Address 2 */}
                  <div className="propertyInfo-section">
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Sub Division:</strong>
                      </p>
                      {data.sub_division}
                    </div>
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Section:</strong>
                      </p>
                      {data.section}
                    </div>
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Block:</strong>
                      </p>
                      {data.block}
                    </div>
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Lot:</strong>
                      </p>
                      {data.lot}
                    </div>
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>County:</strong>
                      </p>
                      {data.county}
                    </div>
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Floor Plan Type:</strong>
                      </p>
                      {data.floor_plan_type}
                    </div>
                  </div>
                  {/* Property Amenities */}
                  <div className="propertyInfo-section">
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Living SF:</strong>
                      </p>
                      {data.living_sq_ft}
                    </div>
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Total SF:</strong>
                      </p>
                      {data.total_sq_ft}
                    </div>
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Stories:</strong>
                      </p>
                      {data.stories}
                    </div>
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Bedrooms:</strong>
                      </p>
                      {data.bedrooms}
                    </div>
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Bathrooms:</strong>
                      </p>
                      {data.bathrooms}
                    </div>
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Office Studios:</strong>
                      </p>
                      {data.offices_studios}
                    </div>
                  </div>
                  {/* Property Amenities 2 */}
                  <div className="propertyInfo-section">
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Pools:</strong>
                      </p>
                      {data.pools}
                    </div>
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Garages:</strong>
                      </p>
                      {data.garages}
                    </div>
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Casita:</strong>
                      </p>
                      {data.casita}
                    </div>
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Media Room:</strong>
                      </p>
                      {data.media_room}
                    </div>
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Game Room:</strong>
                      </p>
                      {data.game_room}
                    </div>
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Google Maps Link:</strong>
                      </p>
                      {data.google_maps_link}
                    </div>
                  </div>
                  {/* Property Governmental Info */}
                  <div className="propertyInfo-section">
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Water Account Name:</strong>
                      </p>
                      {data.water_account_name}
                    </div>
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Water Account Number:</strong>
                      </p>
                      {data.water_account_number}
                    </div>
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Electricity Account Name:</strong>
                      </p>
                      {data.electricity_account_name}
                    </div>
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Electricity Account Number:</strong>
                      </p>
                      {data.electricity_account_number}
                    </div>
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Gas Account Name:</strong>
                      </p>
                      {data.gas_account_name}
                    </div>
                    <div className="label-text-container">
                      <p>
                        {" "}
                        <strong>Gas Account Number:</strong>
                      </p>
                      {data.gas_account_number}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 4th Row Property status */}
          <div className="section-row-container-propertyDetails">
            <div className="section-container-propertyDetails">
              <div className="section-container-propertyDetails-bankInfo">
                {/* Property Status */}
                <div className="column-propertyInfo">
                  <h3> Project Status</h3>
                  <div className="display-project-status-flex">
                    {Object.entries(processesData).map(
                      ([phaseName, subPhases]) => (
                        <div className="checkbox-section" key={phaseName}>
                          <p>
                            <strong>{phaseName}:</strong>
                          </p>
                          {subPhases.map((subPhase) => {
                            const status = statusesData.find(
                              (status) =>
                                status.process_property_status_id ===
                                subPhase.process_id
                            );
                            const isChecked = status
                              ? status.is_completed
                              : false; // Default to false if not found
                            return (
                              <CheckBox
                                labelText={subPhase.name}
                                key={subPhase.id}
                                checked={isChecked}
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    subPhase.process_id,
                                    e.target.checked
                                  )
                                }
                              />
                            );
                          })}
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              {userRole.current !== "others" && (
                <div className="update-button-container">
                  <Button
                    buttonText={"Update"}
                    onClick={(e) => updateStatusesHandler(e)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PropertyDetails;
