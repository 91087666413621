import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setActiveNavTab } from "../../redux/navbar/navbarActions";

import Button from "../common/button/Button";

import hamburgerMenu from "../../assets/images/hamburgerMenu.png";

import { routeNames } from "../../consts/routesNames";
import { navtabs } from "../../consts/navTabs";
import "./MobileNavbar.css";

const MobileNavbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { activeTab } = useSelector((state) => state.navbar);
  const [click, setClick] = useState(false);

  const navMenu = (
    <ul className="nav-list-container">
      <li
        id="nav-list-orders"
        className={
          activeTab === navtabs.HOME
            ? "nav-list-tab mobile-nav-tab-active"
            : "nav-list-tab"
        }
        onClick={() => {
          dispatch(setActiveNavTab(navtabs.HOME));
          navigate(routeNames.HOME);
          setClick(!click);
        }}
      >
        <div className="nav-list-tab-text">Home</div>
      </li>
      <li
        className={
          activeTab === navtabs.CONTACTS
            ? "nav-list-tab mobile-nav-tab-active"
            : "nav-list-tab"
        }
        onClick={() => {
          dispatch(setActiveNavTab(navtabs.CONTACTS));
          navigate(routeNames.CONTACTS);
          setClick(!click);
        }}
      >
        <div className="nav-list-tab-text">Contacts</div>
      </li>
      <li
        className={
          activeTab === navtabs.ITEMS
            ? "nav-list-tab mobile-nav-tab-active"
            : "nav-list-tab"
        }
        onClick={() => {
          dispatch(setActiveNavTab(navtabs.ITEMS));
          navigate(routeNames.ITEMS);
          setClick(!click);
        }}
      >
        <div className="nav-list-tab-text">Items</div>
      </li>
      <li
        className={
          activeTab === navtabs.PROPERTIES
            ? "nav-list-tab mobile-nav-tab-active"
            : "nav-list-tab"
        }
        onClick={() => {
          dispatch(setActiveNavTab(navtabs.PROPERTIES));
          navigate(routeNames.PROPERTIES);
          setClick(!click);
        }}
      >
        <div className="nav-list-tab-text">Projects</div>
      </li>
      <li
        className={
          activeTab === navtabs.COMPANY
            ? "nav-list-tab mobile-nav-tab-active"
            : "nav-list-tab"
        }
        onClick={() => {
          dispatch(setActiveNavTab(navtabs.COMPANY));
          navigate(routeNames.COMPANIES);
          setClick(!click);
        }}
      >
        <div className="nav-list-tab-text">Companies</div>
      </li>
      <li
        className={
          activeTab === navtabs.LISTS
            ? "nav-list-tab mobile-nav-tab-active"
            : "nav-list-tab"
        }
        onClick={() => {
          dispatch(setActiveNavTab(navtabs.LISTS));
          navigate(routeNames.LISTS);
          setClick(!click);
        }}
      >
        <div className="nav-list-tab-text">Lists</div>
      </li>
    </ul>
  );

  return (
    <div
      className={
        activeTab === navtabs.LOGIN || activeTab === navtabs.REGISTER
          ? "hide-mobile-nav"
          : "mobile-nav-container"
      }
    >
      <img
        className="burger-icon"
        src={hamburgerMenu}
        onClick={() => setClick(!click)}
      />
      {click && navMenu}
      <Button
        className={"mobile-nav-button"}
        buttonText={"Logout"}
        onClick={() => navigate("/logout")}
      />
    </div>
  );
};

export default MobileNavbar;
