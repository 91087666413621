export const companyTypeOptions = [
  {
    name: "Person",
    value: "person",
  },
  {
    name: "Company",
    value: "company",
  },
  {
    name: "Others",
    value: "others",
  },
];

export const bankAccountTypeOptions = [
  {
    name: "Checking",
    value: "checking",
  },
  {
    name: "Credit Card",
    value: "credit_card",
  },
  {
    name: "Saving",
    value: "saving",
  },
];
