export const routeNames = {
  HOME: "/",
  LISTS: "/lists",
  LOGIN: "/login",
  REGISTER: "/register",
  LOGOUT: "/logout",
  CONTACTS: "/contacts",
  ADD_CONTACT: "/contacts/add",
  EDIT_CONTACT: "/contacts/edit/:id",
  CONTACT_DETAILS: "/contacts/contact-details",
  ITEMS: "/items",
  ADD_ITEM: "/items/add",
  EDIT_ITEM: "/items/edit/:id",
  ITEM_DETAILS: "/items/item-details",
  PROPERTIES: "/properties",
  ADD_PROPERTY: "/properties/add-property",
  EDIT_PROPERTY: "/properties/edit/:id",
  PROPERTY_DETAILS: "/properties/property-details",
  COMPANIES:"/companies",
  ADD_COMPANIES:"/companies/add-companies",
  EDIT_COMPANIES:"/companies/edit-companies",
  COMPANY_DETAILS:"/companies/companies-details",
  ADD_COMPANY: "/companies/add",
  EDIT_COMPANY: "/companies/edit/:id",
};
