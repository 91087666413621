import React, { useEffect, useState } from "react";
import "./Register.css";
import Button from "../../../components/common/button/Button";
import TextField from "../../../components/common/textfield/TextField";
import logo from "../../../assets/images/AO-logo-black.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ValidateFields,
  ValidateEmail,
  ValidatePhoneNumber,
  ValidatePassword,
  EMAILVALIDATION,
  MOBILEVALIDATION,
} from "../../../utils/formValidation";
import { registerAccount } from "../../../apis/authenticationApis/registerAccount";
import { roleOptions } from "../../../consts/dropDownOptions/registrationOptions"; // Role Options will be an API getting all available Roles
import Input from "../../../components/common/input/Input";
import SelectInput from "../../../components/common/selectInput/SelectInput";
import { routeNames } from "../../../consts/routesNames";
import { navtabs } from "../../../consts/navTabs";
import { setActiveNavTab } from "../../../redux/navbar/navbarActions";
import { useDispatch } from "react-redux";

function Register() {
  const [emailState, setEmailState] = useState("");
  const [userNameState, setUserNameState] = useState("");
  const [mobileNumberState, setMobileNumberState] = useState("");
  const [firstNameState, setFirstNameState] = useState("");
  const [middleNameState, setMiddleNameState] = useState("");
  const [lastNameState, setlastNameState] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [role, setRole] = useState("others");
  const [passwordState, setPasswordState] = useState("");
  const [passwordState2, setPasswordState2] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === routeNames.REGISTER) {
      dispatch(setActiveNavTab(navtabs.REGISTER));
    }
  }, [location.pathname, dispatch]);

  const formValidation = () => {
    const validateMobileNumber = ValidatePhoneNumber(mobileNumberState);
    const validateEmail = ValidateEmail(emailState);
    const validatePassword = ValidatePassword(passwordState, passwordState2);

    if (!validateEmail) {
      alert(EMAILVALIDATION);
      return false;
    }
    if (!validateMobileNumber) {
      alert(MOBILEVALIDATION);
      return false;
    }
    if (validatePassword !== true) {
      alert(validatePassword);
      return false;
    }
    if (!ValidateFields(emailState)) {
      alert("The Email field is required");
      return false;
    }
    if (!ValidateFields(userNameState)) {
      alert("The User Name field is required");
      return false;
    }
    if (!ValidateFields(mobileNumberState)) {
      alert("The Mobile Number field is required");
      return false;
    }
    if (!ValidateFields(firstNameState)) {
      alert("The First Name field is required");
      return false;
    }
    if (!ValidateFields(lastNameState)) {
      alert("The Last Name field is required");
      return false;
    }
    if (!ValidateFields(dateOfBirth)) {
      alert("The Date of Birth field is required");
      return false;
    }
    if (!ValidateFields(role)) {
      alert("The Role field is required");
      return false;
    }
    return true;
  };

  const HandleRegistClick = async (e) => {
    e.preventDefault();

    const isValidated = formValidation();
    if (isValidated) {
      const registerRes = await registerAccount(
        emailState,
        userNameState,
        mobileNumberState,
        firstNameState,
        middleNameState,
        lastNameState,
        dateOfBirth,
        role,
        passwordState
      );
      if (registerRes.error_code === 1) {
        navigate("/login");
      }
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <form onSubmit={(e) => HandleRegistClick(e)}>
        <div className="ellipse"></div>
        <div className="registration-body-container">
          <div className="registration-main-container">
            <img className="registration-logo" src={logo} alt={""} />
            <div className="registration-card-container">
              <div className="registration-labeltext-main-container">
                <>
                  <div className="registration-labeltext-container">
                    <TextField
                      label={"Email"}
                      name="email"
                      type="email"
                      isRequired={true}
                      setValue={setEmailState}
                      value={emailState}
                      placeHolder="Please Enter Your Email ...."
                      maxLength={50}
                    />
                  </div>
                  <div className="registration-labeltext-container">
                    <TextField
                      label={"User Name"}
                      name="username"
                      type="text"
                      isRequired={true}
                      setValue={setUserNameState}
                      value={userNameState}
                      placeHolder="Please Enter Your User Name ...."
                      maxLength={32}
                    />
                  </div>
                  <div className="registration-labeltext-container">
                    <TextField
                      label={"Mobile Number"}
                      name="mobile_number"
                      type="text"
                      isRequired={true}
                      setValue={setMobileNumberState}
                      value={mobileNumberState}
                      placeHolder="Please Enter Your Mobile Number ...."
                      maxLength={32}
                      regex={/^[0-9\b\-]+$/}
                    />
                  </div>
                  <div className="registration-labeltext-container">
                    <TextField
                      name="first_name"
                      label={"First Name"}
                      type="text"
                      isRequired={true}
                      setValue={setFirstNameState}
                      value={firstNameState}
                      placeHolder="Please Enter Your First Name ...."
                      maxLength={32}
                    />
                  </div>
                  <div className="registration-labeltext-container">
                    <TextField
                      name="middle_name"
                      label={"Middle Name"}
                      type="text"
                      isRequired={false}
                      setValue={setMiddleNameState}
                      value={middleNameState}
                      placeHolder="Please Enter Your Middle Name ...."
                      maxLength={32}
                    />
                  </div>
                  <div className="registration-labeltext-container">
                    <TextField
                      label={"Last Name"}
                      name="last_name"
                      type="text"
                      isRequired={true}
                      setValue={setlastNameState}
                      value={lastNameState}
                      placeHolder="Please Enter Your Last Name ...."
                      maxLength={32}
                    />
                  </div>
                  <div className="registration-labeltext-container">
                    <Input
                      label={"Date of Birth"}
                      name="DOB"
                      type="date"
                      setValue={setDateOfBirth}
                      value={dateOfBirth}
                      max={getCurrentDate()}
                      required={true}
                    />
                  </div>
                  {/* <div className="registration-labeltext-container">
                    <SelectInput
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      options={roleOptions}
                      label={"Role"}
                      name="role"
                      placeHolder="Please Choose Your Role ...."
                      required={true}
                    />
                  </div> */}
                  <div className="registration-labeltext-container">
                    <TextField
                      label={"Password"}
                      name="password"
                      type="password"
                      isRequired={true}
                      setValue={setPasswordState}
                      value={passwordState}
                      placeHolder="Please Enter Your Password ...."
                      maxLength={100}
                    />
                  </div>
                  <div className="registration-labeltext-container">
                    <TextField
                      label={"Confirm Password"}
                      name="confrim_password"
                      type="password"
                      isRequired={true}
                      setValue={setPasswordState2}
                      value={passwordState2}
                      placeHolder="Please Confirm Your Password ...."
                      maxLength={100}
                    />
                  </div>

                  <div className="registration-button-container">
                    <Button
                      buttonText={"Submit"}
                      type="button"
                      isDisabled={""}
                      className="login"
                      onClick={(e) => {
                        HandleRegistClick(e);
                      }}
                    />
                  </div>
                  <div className="registration-already-user-container">
                    <label>Already a User ? </label>
                    <a href="/login">{"Login Here"}</a>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
export default Register;
